/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  // getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';

import Picto from '../../atoms/Picto/Picto';
import UnlockerLoader from '../../atoms/UnlockerLoader/UnlockerLoader';

import cn from '../../../utils/cn';

import styles from './Table.module.scss';

function Table({
  data,
  columns,
  fullWidth,
  isLoading,
  initialState,
  meta,
  openable,
  onOpenRow,
  sortViaApi,
  apiSorting,
  onApiSort,
  alreadyOpened,
  disableHover,
  handleInnerCellContentClassName,
  children: collapsableChildren,
}) {
  const [sorting, setSorting] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [openedRows, setOpenedRows] = useState([]);

  const handleToggleRow = useCallback((id, row) => {
    // Close row
    if (openedRows.includes(id)) {
      const index = openedRows.findIndex((val) => val === id);
      setOpenedRows((oldValue) => oldValue.slice(0, index).concat(oldValue.slice(index + 1)));
    } else {
      // Open Row
      setOpenedRows((oldValue) => [...oldValue, id]);
      onOpenRow(id, row.original);
    }
  }, [openedRows, onOpenRow]);

  const {
    getHeaderGroups,
    getRowModel,
    options: { meta: tableMeta },
  } = useReactTable({
    columns: columns || [],
    data: data || [],
    state: {
      sorting,
    },
    initialState,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta,
  });

  const getApiSortingNextDir = useMemo(() => (columnId) => {
    const currentSorting = apiSorting.find((sort) => sort.property === columnId);
    if (!currentSorting) return 'ASC';
    if (currentSorting.direction === 'ASC') return 'DESC';
    return null;
  }, [apiSorting]);

  const handleApiSorting = useCallback((columnId) => {
    const direction = getApiSortingNextDir(columnId);
    const newApiSorting = direction
      ? [{
        property: columnId,
        direction,
      }]
      : [];
    onApiSort(newApiSorting);
  }, [getApiSortingNextDir]);

  const sortPictos = useMemo(() => ({
    ASC: <Picto
      width={15}
      icon="arrow-down"
      transform="rotate(180)"
      color="var(--color-primary-500)"
      className="m-l-10"
    />,
    DESC: <Picto
      width={15}
      icon="arrow-down"
      color="var(--color-primary-500)"
      className="m-l-10"
    />,
  }), []);

  useEffect(() => {
    if (!alreadyOpened) return;
    const rowModel = getRowModel();
    if (alreadyOpened === true && rowModel.rows) {
      setOpenedRows(rowModel.rows.map((row) => row.id));
    } else if (Array.isArray(alreadyOpened) && rowModel.rows) {
      const opened = rowModel.rows
        .filter((row) => alreadyOpened.includes(row.original.uid))
        .map((row) => row.id);
      setOpenedRows(opened);
    }
  }, [getRowModel, alreadyOpened, data]);

  if (isLoading) return <UnlockerLoader size={200} />;

  return (
    <table className={cn([
      styles.table,
      fullWidth ? styles.fullWidth : null,
    ])}
    >
      <thead className={styles.header}>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  style: {
                    width: header.getSize(),
                  },
                }}
                key={header.id}
                colSpan={header.colSpan}
              >
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: cn([
                        header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        styles.headerCell,
                      ]),
                      onClick: sortViaApi && header.column.getCanSort()
                        ? () => handleApiSorting(header.id)
                        : header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {sortPictos[
                      sortViaApi
                        ? apiSorting.find((sort) => sort.property === header.id)?.direction
                        : header.column.getIsSorted()
                    ] ?? (
                    <Picto
                      width={15}
                      icon="arrow-down"
                      color="var(--color-primary-500)"
                      className={cn(['m-l-10', styles.hidden])}
                    />
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel()
          .rows
          // .slice(0, itemsPerPage)
          .map((row) => (
            <React.Fragment key={row.id}>
              <tr
                onMouseLeave={() => setHoveredRow(null)}
                onMouseEnter={() => setHoveredRow(row.id)}
                className={cn([styles.row, tableMeta?.rowStyles?.[row.id] || null])}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                    className={cn([styles.cell])}
                    key={cell.id}
                  >
                    <div className={styles.innerCell}>
                      <div className={cn([
                        styles.innerCellContent,
                        openedRows.includes(row.id) ? styles.hovered : null,
                        hoveredRow === row.id && !disableHover ? styles.hovered : null,
                        handleInnerCellContentClassName(row),
                      ])}
                      />
                    </div>
                    <div className={styles.cellContent}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </td>
                ))}
                {openable && (
                <td
                  key="arrow"
                  style={{ width: '25px' }}
                  className={cn([styles.cell, styles.clickableCell])}
                >
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={openable ? () => handleToggleRow(row.id, row) : null}
                  >
                    <div
                      className={styles.innerCell}
                    >
                      <div className={cn([
                        styles.innerCellContent,
                        openedRows.includes(row.id) ? styles.hovered : null,
                        hoveredRow === row.id && !disableHover ? styles.hovered : null,
                      ])}
                      />
                    </div>
                    <div className={styles.cellContent}>
                      {flexRender(<Picto
                        width={24}
                        icon="arrow-down"
                        transform={openedRows.includes(row.id) ? 'rotate(180)' : null}
                        color="var(--color-primary-500)"
                        className="m-l-10"
                      />)}
                    </div>
                  </div>
                </td>
                )}
              </tr>
              <tr className="m-l-10">
                <td colSpan="100">

                  {openable && (
                  <div className={cn([
                    openedRows.includes(row.id) ? styles.openedRow : styles.closedRow,
                  ])}
                  >
                    {collapsableChildren != null
                      ? React.cloneElement(collapsableChildren, { data: row?.original })
                      : null}
                  </div>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  initialState: PropTypes.shape(),
  meta: PropTypes.shape(),
  openable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onOpenRow: PropTypes.func,
  sortViaApi: PropTypes.bool,
  apiSorting: PropTypes.arrayOf(PropTypes.shape()),
  onApiSort: PropTypes.func,
  alreadyOpened: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  disableHover: PropTypes.bool,
  handleInnerCellContentClassName: PropTypes.func,
};

Table.defaultProps = {
  fullWidth: false,
  isLoading: false,
  initialState: {},
  meta: {},
  openable: false,
  children: null,
  onOpenRow: null,
  sortViaApi: false,
  apiSorting: [],
  onApiSort: () => {},
  alreadyOpened: false,
  disableHover: false,
  handleInnerCellContentClassName: () => {},
};

export default Table;
