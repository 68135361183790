/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import DocumentList from '../../../components/organisms/DocumentList/DocumentList';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';
import AddPropertyInvoiceModal from './AddPropertyInvoiceModal';

// Hooks
import { useAppContext } from '../../../store/context';

// Services
import { getPropertyInvoices, deletePropertyInvoice } from '../../../services/property';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';
import { showModal } from '../../../utils/modal';

import styles from './PropertyInvoices.module.scss';

const ADD_PROPERTY_INVOICE_MODAL_ID = 'add-property-invoice-modal';

// TODO: handle charge, amount
function PropertyInvoices({
  propertyQuery,
}) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const match = useMatch('/property/:id');

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['property-invoices', user, page, itemsPerPage],
    queryFn: async () => {
      if (user) {
        return getPropertyInvoices(match?.params?.id, {
          page,
          itemsPerPage,
        });
      }
      return [];
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  const documentDeleteMutation = useMutation({
    mutationFn: deletePropertyInvoice,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = uiBuilders['/property/ui'].invoiceTypes[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
    {
      header: t('property.tabs.invoices.fields.charge'),
      accessorKey: 'charge',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (original?.isTemplate ? null : (
        <p className="p-2-500">
          {t(`property.tabs.invoices.charge.${original?.charge}`)}
        </p>
      )),
    },
    {
      header: t('property.tabs.invoices.fields.amount'),
      accessorKey: 'amount',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (original?.isTemplate ? null : (
        <p className="p-2-500">
          {utils.centsToEuro(original?.amount)}
        </p>
      )),
    },
  ]), [t, uiBuilders]);

  const fileList = useMemo(() => {
    if (uiBuilders) {
      const { invoiceTypes } = uiBuilders['/property/ui'];
      return Object.keys(invoiceTypes);
    }
    return [];
  }, [uiBuilders]);

  return (
    <div className="m-t-50">
      <h2>{t('property.tabs.invoices.title')}</h2>
      <div className="m-t-10 m-b-60">
        <FormInfoRequired content={t('global.documents.info')} />
      </div>
      <DocumentList
        className="m-t-25"
        fileList={fileList}
        columns={columns}
        documentListData={documentListData}
        addButton={{
          enabled: true,
          label: t('property.tabs.invoices.addDocument'),
          icon: 'plus',
        }}
        onAddBtnClick={() => {
          showModal(ADD_PROPERTY_INVOICE_MODAL_ID);
        }}
        onFileListElementDelete={(element) => {
          documentDeleteMutation.mutate(element?.uid);
        }}
        pagination={{
          page,
          itemsPerPage,
          setPage,
          setItemsPerPage,
        }}
        isDocumentListFetching={documentListFetching}
      />
      <AddPropertyInvoiceModal
        refetchDocumentList={refetchDocumentList}
        modalId={ADD_PROPERTY_INVOICE_MODAL_ID}
        property={propertyQuery?.data?.data || {}}
      />
    </div>
  );
}

PropertyInvoices.propTypes = {
  propertyQuery: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default PropertyInvoices;
