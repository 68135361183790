import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Table,
  SelectInput,
  utils,
  Picto,
  TextInput,
  Message,
  Button,
  Pagination,
  ToggleInput,
} from 'ui-library-unlocker';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';
import SelectTenant from './SelectTenant';

// Services
import { validateHousingBenefitImport } from '../../../services/housingBenefits';

// Utils
import { housingBenefitsImportSchema } from '../../../utils/forms/housingBenefitsSchema';
import { displayError } from '../../../utils/forms/form';
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { LEASE_STATUS } from '../../../utils/constants';

import styles from './HousingBenefitsImportValidation.module.scss';

const STATUS_OPTIONS = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected', // think to change also in schema
};

const CONFIRM_MODAL_ID = 'confirm-reset-modal';

function LineError({ data, formikRows }) {
  const { t } = useTranslation();
  return (
    <p className={styles.errorText}>
      {t(`housingBenefits.crud.import.errors.${formikRows
        ?.find((r) => r.uid === data.uid)?.error
      }`)}
    </p>
  );
}

LineError.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string,
  }),
  formikRows: PropTypes.arrayOf(PropTypes.shape({})),
};

LineError.defaultProps = {
  data: {},
  formikRows: [],
};

function HousingBenefitsImportValidation({
  importData,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tenantsLeases, setTenantsLeases] = useState([]);
  const [importedWithErrors, setImportedWithErrors] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  useEffect(() => {
    setTenantsLeases(importData?.rows.map((row) => row.leases) || []);
  }, [importData]);

  const validateImportMutation = useMutation({
    mutationFn: (data) => validateHousingBenefitImport(data),
    onSuccess: ({ data }) => {
      utils.toast.success(t('housingBenefits.crud.import.success'));
      if (!data?.numberOfFailed) {
        navigate('/housing-benefits');
      } else {
        formik.setFieldValue('rows', formik.values.rows
          .filter((row) => data.rowsInError.map((error) => error.uid).includes(row.uid))
          .map((row) => ({
            ...row,
            status: STATUS_OPTIONS.REJECTED,
            forcedDisabled: true,
            error: data.rowsInError.find((err) => err.uid === row.uid)?.error,
          })));
        setImportedWithErrors(true);
      }
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const initialValues = useMemo(() => {
    const getDefaultLease = (leases) => {
      if (!leases) return null;

      const ongoingLeases = leases.filter((lease) => !lease.terminated);
      if (ongoingLeases.length === 0) return leases[0];

      const previouslyUsed = ongoingLeases.find((lease) => lease.previouslyUsed);
      if (previouslyUsed) return previouslyUsed;
      return ongoingLeases[0];
    };
    return {
      rows: importData?.rows.map((row) => {
        const defaultLease = getDefaultLease(row.leases);
        const forceDisable = (!defaultLease && row.tenant?.uid) || defaultLease?.terminated;
        return ({
          uid: row.uid,
          tenant: row.tenant
            ? {
              value: row.tenant.uid,
              label: `${row.tenant.firstName} ${row.tenant.lastName} - ${row.tenant.benefitNumber}`,
            }
            : null,
          lease: defaultLease?.uid,
          status: forceDisable ? STATUS_OPTIONS.REJECTED : null,
          amount: utils.centsToEuro(row.amount),
          forcedDisabled: forceDisable,
          leaseRentWithCharges: defaultLease?.rentWithCharges
            ? utils.centsToEuro(defaultLease.rentWithCharges)
            : null,
          recurrent: !!row.recurrent,
        });
      }) || [],
    };
  }, [importData]);

  const formik = useFormik({
    initialValues,
    validationSchema: housingBenefitsImportSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      let dataToMutate = { importUid: importData?.importUid };
      if (values.rows?.some((row) => row.status === STATUS_OPTIONS.ACCEPTED)) {
        dataToMutate = {
          ...dataToMutate,
          state: 'submitted',
          rows: values.rows?.map((row) => ({
            uid: row.uid,
            tenant: row.tenant?.value,
            lease: row.lease || null,
            status: row.status,
            amount: utils.euroToCents(row.amount),
            recurrent: !!row.recurrent,
          })),
        };
      } else {
        dataToMutate = {
          ...dataToMutate,
          state: 'cancelled',
        };
      }
      validateImportMutation.mutate(dataToMutate);
    },
  });

  const getActionPictos = useCallback((status, index) => {
    if (!status) {
      const lineHasError = Object.keys(formik.errors.rows?.[index] || {})?.some((errKey) => errKey !== 'status');
      return (
        <>
          <Picto
            icon="tick-circle"
            onClick={() => !lineHasError && formik.setFieldValue(`rows[${index}].status`, STATUS_OPTIONS.ACCEPTED)}
            color={lineHasError ? 'var(--color-primary-400)' : 'var(--color-accent-green)'}
            width={30}
            className={styles.importActionPicto}
          />
          <Picto
            icon="close-circle"
            onClick={() => formik.setFieldValue(`rows[${index}].status`, STATUS_OPTIONS.REJECTED)}
            color="var(--color-accent-red)"
            width={30}
            className={utils.cn([styles.importActionPicto, 'm-l-10'])}
          />
        </>
      );
    }
    if (formik.values.rows?.[index]?.forcedDisabled) {
      return null;
    }
    return (
      <Picto
        icon="undo"
        onClick={() => formik.setFieldValue(`rows[${index}].status`, null)}
        color="var(--color-primary-400)"
        width={30}
        className={styles.importActionPicto}
      />
    );
  }, [formik]);

  const handleChangeLease = useCallback((index, option) => {
    formik.setFieldValue(`rows[${index}].lease`, option.value);
    formik.setFieldValue(`rows[${index}].leaseRentWithCharges`, option.rentWithCharges);
  }, [formik]);

  const handleTenantSelect = useCallback((index, option) => {
    formik.setFieldValue(`rows[${index}].tenant`, option);
    formik.setFieldValue(`rows[${index}].lease`, null);
    formik.setFieldValue(`rows[${index}].leaseRentWithCharges`, null);
  }, [formik]);

  const handleChangeLeaseOptions = useCallback((index, list) => {
    setTenantsLeases((prev) => {
      const newLeases = [...prev];
      newLeases[index] = list?.map((item) => ({
        uid: item.uid,
        name: item.name,
        rentWithCharges: item.rent + item.charges,
        previouslyUsed: false,
        terminated: item.status === LEASE_STATUS.TERMINATED,
      })) || [];
      return newLeases;
    });
  }, [setTenantsLeases]);

  const getLeaseNoOptionsMessage = useCallback((index) => {
    if (formik.values.rows?.[index]?.tenant) {
      return t('housingBenefits.crud.import.errors.tenantHasNoLease');
    }
    return t('housingBenefits.crud.import.leasePlaceholder');
  }, [t, formik.values.rows]);

  const columns = useMemo(() => ([
    {
      header: t('housingBenefits.crud.import.columns.tenant'),
      accessorKey: 'tenant',
      size: 220,
      enableSorting: false,
      cell: ({ row: { index, original: { tenant } } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        return (
          <span>
            {tenant
              ? (
                <>
                  {`${tenant.firstName} ${tenant.lastName}`}
                  <br />
                  {tenant.benefitNumber}
                </>
              )
              : (
                <SelectTenant
                  id={`row-${paginatedIndex}-tenant`}
                  name={`rows[${paginatedIndex}].tenant`}
                  onChange={(option) => handleTenantSelect(paginatedIndex, option)}
                  formikValues={formik.values.rows?.[paginatedIndex]}
                  error={displayError(t, formik, `rows[${paginatedIndex}].tenant`, null, {
                    nestedValue: true,
                  })}
                  disabled={[
                    STATUS_OPTIONS.ACCEPTED,
                    STATUS_OPTIONS.REJECTED,
                  ].includes(formik.values.rows?.[paginatedIndex]?.status)}
                  handleChangeLeaseOptions={(list) => handleChangeLeaseOptions(paginatedIndex, list)}
                />
              )}
          </span>
        );
      },
    },
    {
      header: t('housingBenefits.crud.import.columns.lease'),
      accessorKey: 'leases',
      size: 220,
      enableSorting: false,
      cell: ({ row: { index, original: { tenant } } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        const leaseOptions = tenantsLeases[paginatedIndex]?.map((lease) => ({
          label: `${lease.terminated ? `(${t('lease.terminated')}) ` : ''}${lease.name}`,
          value: lease.uid,
          terminated: lease.terminated,
          rentWithCharges: utils.centsToEuro(lease.rentWithCharges),
        }));
        const status = formik.values.rows?.[paginatedIndex]?.status;
        const formikLeaseUid = formik.values.rows?.[paginatedIndex]?.lease;
        return (
          <SelectInput
            id={`row-${paginatedIndex}-lease`}
            name={`rows[${paginatedIndex}].lease`}
            options={leaseOptions || []}
            onChange={(option) => handleChangeLease(paginatedIndex, option)}
            onBlur={formik.handleBlur}
            value={leaseOptions?.find((item) => item.value === formikLeaseUid)}
            error={!!displayError(t, formik, `rows[${paginatedIndex}].lease`, null, {
              nestedValue: true,
            })}
            className={styles.input}
            disabled={status === STATUS_OPTIONS.ACCEPTED || status === STATUS_OPTIONS.REJECTED}
            isOptionDisabled={(option) => option.terminated}
            label={!formikLeaseUid && tenant ? t('housingBenefits.crud.import.errors.tenantHasNoLease') : null}
            noOptionsMessage={() => getLeaseNoOptionsMessage(index)}
          />
        );
      },
    },
    {
      header: t('housingBenefits.crud.import.columns.rentWithCharges'),
      accessorKey: 'rentWithCharges',
      size: 100,
      enableSorting: false,
      cell: ({ row: { index } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        return (
          <span>
            {`${formik.values.rows?.[paginatedIndex]?.leaseRentWithCharges || '--'} €`}
          </span>
        );
      },
    },
    {
      header: t('housingBenefits.crud.import.columns.previousAmount'),
      accessorKey: 'previousAmount',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { previousAmount } } }) => (
        <span>
          {previousAmount ? `${utils.centsToEuro(previousAmount)} €` : '--'}
        </span>
      ),
    },
    {
      header: t('housingBenefits.crud.import.columns.amount'),
      accessorKey: 'amount',
      size: 100,
      enableSorting: false,
      cell: ({ row: { index, original: { amount } } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        const status = formik.values.rows?.[paginatedIndex]?.status;
        return (
          <TextInput
            type="number"
            min="0"
            max={formik.values.rows?.[paginatedIndex]?.leaseRentWithCharges}
            step="0.01"
            id={`row-${paginatedIndex}-amount`}
            name={`rows[${paginatedIndex}].amount`}
            // table re-renders and input loses focus if we update formik on change
            onChange={() => null}
            // so we update on blur instead
            onBlur={(e) => formik.setFieldValue(
              `rows[${paginatedIndex}].amount`,
              e.target.value || utils.centsToEuro(amount),
            )}
            // defaultValue is used to prevent input from losing focus
            defaultValue={formik.values.rows?.[paginatedIndex]?.amount}
            icon="euro"
            iconSize={20}
            iconColor="var(--color-primary-300)"
            inputClassName={utils.cn([styles.input, styles.number])}
            disabled={status === STATUS_OPTIONS.ACCEPTED || status === STATUS_OPTIONS.REJECTED}
            error={displayError(t, formik, `rows[${paginatedIndex}].amount`, null, {
              errorIfUntouched: true,
              nestedValue: true,
              errorConcatValues: {
                maxValue: formik.values.rows?.[paginatedIndex]?.leaseRentWithCharges,
              },
            })}
          />
        );
      },
    },
    {
      header: t('housingBenefits.crud.import.columns.recurrent'),
      accessorKey: 'recurrent',
      size: 100,
      enableSorting: false,
      cell: ({ row: { index } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        return (
          <ToggleInput
            id={`row-${paginatedIndex}-recurrent`}
            name={`rows[${paginatedIndex}].recurrent`}
            checked={!!formik.values.rows?.[paginatedIndex]?.recurrent}
            onChange={(check) => formik.setFieldValue(`rows[${paginatedIndex}].recurrent`, check)}
            disabled={[
              STATUS_OPTIONS.ACCEPTED,
              STATUS_OPTIONS.REJECTED,
            ].includes(formik.values.rows?.[paginatedIndex]?.status)}
          />
        );
      },
    },
    {
      header: t('housingBenefits.crud.import.columns.impactDueDate'),
      accessorKey: 'impactDueDate',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { impactDueDate } } }) => (
        <span>
          {impactDueDate ? format(new Date(impactDueDate), 'dd MMM yyyy', { locale: fr }) : '-'}
        </span>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { index } }) => {
        const paginatedIndex = (page - 1) * itemsPerPage + index;
        return (
          <div>
            {getActionPictos(formik.values.rows?.[paginatedIndex]?.status, paginatedIndex)}
          </div>
        );
      },
    },
  ]), [
    t,
    formik,
    getActionPictos,
    displayError,
    handleChangeLease,
    tenantsLeases,
    handleTenantSelect,
    handleChangeLeaseOptions,
    page,
    itemsPerPage,
  ]);

  const handleAllLinesStatus = useCallback((status) => {
    formik.setValues({
      rows: formik.values.rows.map((row) => ({
        ...row,
        status,
      })),
    });
  }, [formik]);

  const validateAllLines = useCallback(() => handleAllLinesStatus(STATUS_OPTIONS.ACCEPTED), [handleAllLinesStatus]);

  const rejectAllLines = useCallback(() => handleAllLinesStatus(STATUS_OPTIONS.REJECTED), [handleAllLinesStatus]);

  const handleInnerCellContentClassName = useCallback((row) => {
    if (!row) return null;
    const paginatedIndex = (page - 1) * itemsPerPage + row.index;
    if (formik.values.rows[paginatedIndex]?.status === STATUS_OPTIONS.ACCEPTED) return styles.accepted;
    if (formik.values.rows[paginatedIndex]?.status === STATUS_OPTIONS.REJECTED) return styles.rejected;
    return null;
  }, [formik]);

  const handleReset = useCallback(() => {
    formik.handleReset();
    hideModal(CONFIRM_MODAL_ID);
  }, [formik]);

  const data = useMemo(() => importData?.rows
    ?.filter((row) => formik.values.rows.map((r) => r.uid).includes(row.uid))
    || [], [formik.values.rows]);

  const openedRows = useMemo(
    () => (importedWithErrors
      ? data.map((row) => row.uid)
      : []),
    [data, importedWithErrors],
  );

  const paginatedData = useMemo(
    () => data
      ?.slice((page - 1) * itemsPerPage, page * itemsPerPage),
    [data, page, itemsPerPage],
  );

  return (
    <div className="m-t-30">
      {importedWithErrors ? (
        <Message
          variant="error"
          content={t('housingBenefits.crud.import.errors.importedWithErrors')}
        />
      ) : (
        <>
          <Message
            variant="info"
            content={t('housingBenefits.crud.import.description')}
          />
          <div className={utils.cn([styles.importBtnWrapper, 'm-t-10'])}>
            <Button
              label={t('housingBenefits.crud.import.validateAllLines')}
              variant="secondary"
              onClick={validateAllLines}
              icon="tick-circle"
            />
            <Button
              label={t('housingBenefits.crud.import.rejectAllLines')}
              variant="danger"
              onClick={rejectAllLines}
              icon="close-circle"
            />
            <Button
              label={t('housingBenefits.crud.import.reset')}
              variant="grey"
              onClick={() => showModal(CONFIRM_MODAL_ID)}
              icon="undo"
            />
          </div>
        </>
      )}
      <div className="m-t-30">
        <Table
          fullWidth
          columns={columns}
          data={paginatedData}
          disableHover
          handleInnerCellContentClassName={handleInnerCellContentClassName}
          openable={importedWithErrors}
          alreadyOpened={openedRows}
        >
          <LineError formikRows={formik.values.rows} />
        </Table>
        {data?.length && (
          <div className={styles.pagination}>
            <Pagination
              breakLine="..."
              totalCount={data?.length}
              currentPage={page}
              onPageChange={(p) => setPage(p)}
              initialItemsPerPage={itemsPerPage}
              onItemsPerPageChange={(items) => setItemsPerPage(items)}
            />
          </div>
        )}
        {formik.errors?.rows?.some((err) => err?.status) && (
          <Message
            variant="error"
            className="m-t-30"
            content={t('housingBenefits.crud.import.errors.lineStillNotVerified')}
          />
        )}
        {importedWithErrors ? (
          <Button
            label={t('housingBenefits.crud.import.goBackToList')}
            onClick={() => navigate('/housing-benefits')}
            variant="secondary"
            className="m-t-50"
            size="large"
          />
        ) : (
          <Button
            label={t('global.confirm')}
            onClick={formik.handleSubmit}
            loading={validateImportMutation.isLoading}
            className="m-t-50"
            size="large"
          />
        )}
      </div>
      <ConfirmationModal
        id={CONFIRM_MODAL_ID}
        onSubmit={handleReset}
        onCancel={() => hideModal(CONFIRM_MODAL_ID)}
        title={t('housingBenefits.crud.import.confirmResetTitle')}
        description={t('housingBenefits.crud.import.confirmResetDescription')}
        size="small"
      />
    </div>
  );
}

HousingBenefitsImportValidation.propTypes = {
  importData: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    importUid: PropTypes.string,
  }),
};

HousingBenefitsImportValidation.defaultProps = {
  importData: {},
};

export default HousingBenefitsImportValidation;
